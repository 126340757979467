import { Body, Button, Divisor, Separator } from 'atomic'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
import * as React from 'react'
import {
  MediaIconCellContentWrapper,
  MediaIconCellImgWrapper,
  MediaIconCellLinkWrapper,
  MediaIconCellStyled,
  MediaIconCellTitle
} from './media-icon-cell.style'

export interface MediaIconCellProps {
  imgSrc: string
  imgAlt: string
  title: string
  body?: string
  linkText?: string
  linkTo: string
  external?: boolean
}

export const imgSize = '76'
export const MediaIconCell: React.FunctionComponent<MediaIconCellProps> = props => (
  <a href={props.linkTo} target={props.external ? '_blank' : '_self'}>
    <MediaIconCellStyled>
      <Separator />
      <MediaIconCellImgWrapper>
        <LazyLoadImage src={props.imgSrc} width={imgSize} height={imgSize} alt={props.imgAlt} />
      </MediaIconCellImgWrapper>

      <MediaIconCellContentWrapper>
        <MediaIconCellTitle cell>{props.title}</MediaIconCellTitle>
        {props.body && (
          <>
            <Separator />
            <Body>{props.body}</Body>
          </>
        )}

        {props.linkText && (
          <>
            <Separator />
            <MediaIconCellLinkWrapper>
              <Button
                id="button-media-icon-cell"
                kind="link"
                to={props.linkTo}
                external={props.external}
                target={props.external ? '_blank' : '_self'}
              >
                {props.linkText}
              </Button>
            </MediaIconCellLinkWrapper>
          </>
        )}
      </MediaIconCellContentWrapper>

      <Separator />
      <Divisor />
    </MediaIconCellStyled>
  </a>
)
