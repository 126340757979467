import { Col, Grid, Row, Separator } from "atomic";
import { Segmented } from "atomic/legacy/atm.segmented";
import { Placeholder } from "atomic/legacy/mol.placeholder";
import { Form } from "atomic/legacy/obj.form/form.component";
import { ListNoBullets } from "site/src/components/atm.list-no-bullets/ListNoBullets.component";
import SvgBuscaSemResultado from "atomic/atm.svg-icon/ic-busca-sem-resultado";
import { FieldsWrapper } from "site/src/components/atm.wrapper/wrapper.component";
import { getListJsonLd } from "site/src/components/legacy/mol.seo/build-list-json-ld";
import { SEO } from "site/src/components/legacy/mol.seo/seo.component";
import { PaginationContainer } from "site/src/components/legacy/obj.pagination-container/pagination.container";
import { PaginationRow } from "site/src/components/legacy/pagination-wrapper.component";
import { SpecialitiesField } from "site/src/components/legacy/specialities-field.component";
import { FuseSearch } from "site/src/components/mol.local-search/fuse-search.component";
import { IndexParams } from "site/src/components/mol.local-search/fuse-search.utils";
import { MediaIconCell } from "site/src/components/mol.media-icon-cell/media-icon-cell.component";
import NumberOfResults from "site/src/components/mol.number-of-results/number-of-results.component";
import TitleWithBreadcrumbRow from "site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component";
import { CosmicjsEspecialidadesEdge } from "site/src/data/graphql/graphql-types";
import { appPaths } from "utils/path";
import { isExternalUrl, normalizeUrl } from "utils/url";
import { navigate } from "gatsby";
import * as queryString from "query-string";
import * as React from "react";

export interface DiagnosticGuideItem {
  title: string;
  slug: string;
  icon: string;
  description: string;
  buttonText: string;
  especialidades: string[];
}
export enum DiagnosticGuideKind {
  diagnosticManual,
  diagnosticScript
}

interface DiagnosticGuidesPageProps {
  list: DiagnosticGuideItem[];
  specialities: {
    edges: CosmicjsEspecialidadesEdge[];
  };
  selectedPage: DiagnosticGuideKind;
  location: Location;
  siteUrl: string;
  seoData: {
    title: string;
    description: string;
    imgAlt: string;
    imgSrc: string;
  };
}

interface PageData {
  title: string;
  items: DiagnosticGuideItem[];
  searchPlaceholder: string;
  diagnosticManualTabName: string;
  diagnosticScriptTabName: string;
}

export interface DiagnosticGuidPageQueryParams {
  especialidade: string;
  [key: string]: unknown;
}

const DiagnosticGuidesPage: React.FunctionComponent<DiagnosticGuidesPageProps> = props => {
  const pageData: PageData = {
    title: "Guias Diagnósticos",
    items: props.list,
    searchPlaceholder: "Buscar por palavra-chave",
    diagnosticManualTabName: "Manuais Diagnósticos",
    diagnosticScriptTabName: "Roteiros Diagnósticos"
  };
  const handleSelectChange = (index: DiagnosticGuideKind) => {
    if (index === DiagnosticGuideKind.diagnosticManual) {
      navigate(`${appPaths.diagnosticManual.path.doctorUrl}`);
    } else {
      navigate(`${appPaths.diagnosticScript.path.doctorUrl}`);
    }
  };
  const siteUrl = props.siteUrl;
  const [items, setItems] = React.useState(props.list);
  const onResult = list => {
    if (list) {
      setItems(list);
    }
  };

  const queryParam: DiagnosticGuidPageQueryParams = queryString.parse(
    props.location.search
  ) as any;
  const especialidade = queryParam.especialidade;
  const onValueChange = value => {
    const param: DiagnosticGuidPageQueryParams = value.value && {
      especialidade: value.value
    };
    navigate(`${props.location.pathname}?${queryString.stringify(param)}`);
  };

  const indexParams: IndexParams<DiagnosticGuideItem> = {
    data: props.list,
    keys: [
      {
        name: "title",
        weight: 0.8
      },
      {
        name: "description",
        weight: 0.2
      }
    ]
  };

  return (
    <Grid>
      <TitleWithBreadcrumbRow title={pageData.title} />
      <Row bottom={"xs"} mb>
        <Col xs={12} md={8}>
          <FieldsWrapper>
            <FuseSearch
              id="input-diagnostic-guides-search"
              indexParams={indexParams}
              onResultChange={onResult}
              placeholder={pageData.searchPlaceholder}
            />
            <Form id="form-diagnostic-guides" onSubmit={null} />
          </FieldsWrapper>
        </Col>
        <Col xs={12} md={4}>
          <SpecialitiesField
            allCosmicjsEspecialidades={props.specialities}
            onValueChange={onValueChange}
            initialValueSlug={especialidade}
          />
        </Col>
      </Row>
      <Row mb center="xs">
        <Col md={8}>
          <Segmented
            defaultIndex={props.selectedPage}
            onChange={index => handleSelectChange(index)}
            animated
          >
            <Segmented.Item id="segmented-item-diagnostic-manual">
              {pageData.diagnosticManualTabName}
            </Segmented.Item>
            <Segmented.Item id="segmented-item-diagnostic-script">
              {pageData.diagnosticScriptTabName}
            </Segmented.Item>
          </Segmented>
          <Separator />
        </Col>
      </Row>
      <Row mb>
        <Col xs={12} md={8}>
          {pageData.items.length > 0 ? (
            <PaginationContainer>
              {paginationProps => {
                const startIndex =
                  (paginationProps.pagination.current - 1) *
                  paginationProps.limit.current;
                const endIndex =
                  paginationProps.pagination.current *
                  paginationProps.limit.current;
                const data = items.filter(
                  item =>
                    !especialidade ||
                    (item.especialidades &&
                      item.especialidades.includes(especialidade))
                );
                const pageList = data.slice(startIndex, endIndex);
                const numberOfItems = data.length;
                return (
                  <>
                    <SEO
                      jsonld={getListJsonLd(
                        pageList.map(item => ({
                          url:
                            props.selectedPage ===
                            DiagnosticGuideKind.diagnosticManual
                              ? normalizeUrl(`${siteUrl}${item.slug}`)
                              : item.slug
                        }))
                      )}
                      socialMedia={{
                        canonicalUrl: `${siteUrl}${
                          props.selectedPage ===
                          DiagnosticGuideKind.diagnosticManual
                            ? appPaths.diagnosticManual.path.doctorUrl
                            : appPaths.diagnosticScript.path.doctorUrl
                        }`,
                        title: props.seoData.title,
                        image: props.seoData.imgSrc,
                        imageAlt: props.seoData.imgAlt,
                        description: props.seoData.description
                      }}
                    />

                    <Row mt mb>
                      <Col>
                        <NumberOfResults numberOfItems={numberOfItems} />
                        <Separator />
                      </Col>
                    </Row>
                    <Row mb>
                      <Col xs={12}>
                        <ListNoBullets id="list-diagnostic-guides">
                          {pageList &&
                            pageList.map(item => (
                              <Row mb>
                                <Col xs={12}>
                                  <MediaIconCell
                                    imgSrc={item.icon}
                                    title={item.title}
                                    imgAlt={`Icone representando ${item.title}`}
                                    linkTo={item.slug}
                                    body={item.description}
                                    linkText={item.buttonText}
                                    external={isExternalUrl(item.slug)}
                                  />
                                </Col>
                              </Row>
                            ))}
                        </ListNoBullets>
                      </Col>
                    </Row>
                    <PaginationRow
                      pagination={{
                        ...paginationProps.pagination,
                        total: pageData.items.length
                      }}
                      limit={paginationProps.limit}
                    />
                  </>
                );
              }}
            </PaginationContainer>
          ) : (
            <Placeholder
              icon={<SvgBuscaSemResultado />}
              title={"Nenhum resultado encontrado"}
              description="Tente buscar outro termo ou ligue para nossa central de atendimento."
            />
          )}
        </Col>
      </Row>
    </Grid>
  );
};

export default DiagnosticGuidesPage;
