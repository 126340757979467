import { Border, Color, H3, Spacing } from 'atomic'
import ColorFunc from 'color'
import styled from 'styled-components'

export const MediaIconCellTitle = styled(H3)``

export const MediaIconCellStyled = styled.li`
  overflow: auto;
  background-color: ${Color.White};
  :hover {
    ${MediaIconCellTitle} {
      text-decoration: underline;
    }
  }
`
export const MediaIconCellContentWrapper = styled.div`
  margin: 0;
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
`

export const MediaIconCellLinkWrapper = styled.div`
  flex: 1;
`

export const MediaIconCellImgWrapper = styled.div`
  float: left;
  margin-right: ${Spacing.Large};
  border-radius: ${Border.Radius};
  overflow: hidden;
  background-color: ${props =>
    ColorFunc(props.theme.color.primary)
      .alpha(0.1)
      .hsl()
      .string()};
`
